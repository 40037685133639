import React from "react";
import { SvgIcon } from "@material-ui/core";

const ComponenteComponent = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 21.013 24.382">
    <defs>
      <style>{".a{fill:inherit;}"}</style>
    </defs>
    <g transform="translate(5.994 20.114)">
      <path
        className="a"
        d="M918.333,214.539c-2.733-.052-5.661-.778-5.633-2.252.024-1.291,2.3-1.886,4.428-2.013l.06,1.008c-2.433.145-3.474.791-3.478,1.024,0,.062.2.38,1.149.7a13.659,13.659,0,0,0,7,.132c.961-.285,1.174-.6,1.175-.657,0-.232-1.009-.916-3.429-1.154l.1-1c2.114.207,4.364.888,4.339,2.177C924.018,213.974,921.065,214.59,918.333,214.539Z"
        transform="translate(-912.699 -210.274)"
      />
    </g>
    <g transform="translate(11.162 13.392)">
      <rect
        className="a"
        width={8.834}
        height={1.01}
        transform="translate(0 8.832) rotate(-88.918)"
      />
    </g>
    <g transform="translate(3.021 1.24)">
      <path
        className="a"
        d="M898.095,65.6c-3.341-.063-8.986-.684-8.95-2.609.015-.8.954-1.391,2.87-1.8l.209.988c-1.527.323-2,.7-2.066.828.06.137.574.567,2.268.962a32.019,32.019,0,0,0,11.4.215c1.709-.331,2.238-.741,2.3-.876-.06-.137-.574-.567-2.269-.962a28.591,28.591,0,0,0-5.688-.619,33.226,33.226,0,0,0-3.946.15l-.1-1a34.266,34.266,0,0,1,4.068-.155c3.341.063,8.986.683,8.95,2.609S901.436,65.662,898.095,65.6Z"
        transform="translate(-889.145 -60.714)"
      />
    </g>
    <g transform="translate(6.348 6.776)">
      <path
        className="a"
        d="M921.089,106.257a24.555,24.555,0,0,1-5.579-.7l.257-.976a25.781,25.781,0,0,0,10.635.217l.216.986A24.729,24.729,0,0,1,921.089,106.257Z"
        transform="translate(-915.51 -104.584)"
      />
    </g>
    <g transform="translate(3.223 3.494)">
      <path
        className="a"
        d="M899.33,89.7l-8.588-10.488.781-.64,7.836,9.57,8.2-9.286.757.668Z"
        transform="translate(-890.741 -78.574)"
      />
    </g>
    <path
      className="a"
      d="M868.664,57.938a3.526,3.526,0,1,1,3.592-3.459l-1.009-.019a2.513,2.513,0,1,0-1.787,2.362l.292.966A3.523,3.523,0,0,1,868.664,57.938Z"
      transform="translate(-865.205 -50.887)"
    />
  </SvgIcon>
);

export default ComponenteComponent;
