import React from 'react'
import { View, Animated } from 'react-native'
import Touchable from 'react-native-platform-touchable';

const CollapsibleBlue = ({ header, content }: { header: any, content: any }) => {
  const [animatedValue] = React.useState(new Animated.Value(0));
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => { 
    Animated.timing(animatedValue, {
      toValue: isOpen ? 1000 : 0,
      duration: 100
    }).start()
  }, [animatedValue, isOpen])

  return (
    <View style={{ backgroundColor: '#E9F0F8' }}>
      <Touchable onPress={() => setIsOpen(!isOpen)}>{header}</Touchable>
      <Animated.View style={{
        maxHeight: animatedValue,
        overflow: 'hidden'
      }}>{content}</Animated.View>
    </View>
  )
}

export default CollapsibleBlue
