import React from 'react';
import { Button, ButtonProps } from 'react-native-elements';
import * as colors from '../theme/colors';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import { ViewProps, Dimensions, View } from 'react-native';
import { Block, ViewWithOneOrMultipleChilds } from './views';
import ItText, { itGetFontScale } from './ItText';
import Touchable from 'react-native-platform-touchable';
import ChevSvg from './right_chev.svg';
import { scale, moderateScale } from 'react-native-size-matters';
import { AllowOnlyOne, FontAwesome5IconVariants } from 'react-native-vector-icons/FontAwesome5';

const { width } = Dimensions.get('window');

export const ItBlockButton: React.FC<ButtonProps> = ({ style, ...props }: ButtonProps) => (
  <View style={{ width: '100%' }}>
    <ItButton
      {...props}
      containerStyle={{ width: '100%' }}
      buttonStyle={[
        {
          borderRadius: scale(30),
          backgroundColor: colors.primary,
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
      ]}
    />
  </View>
);

export const ItBlockButtonOutline: React.FC<ButtonProps> = ({ style, ...props }: ButtonProps) => (
  <Button
    {...props}
    type="outline"
    containerStyle={{
      borderColor: colors.primary,
      borderWidth: 2,
      borderRadius: 30,
    }}
    style={{ width: width - 50 }}
    buttonStyle={[style, { width: 'auto', borderRadius: 30 }]}
  />
);

interface ButtonClearProps extends ButtonProps {
  iconName: string;
  iconSize?: number;
  color?: string;
  iconType?: 'solid' | 'light';
}

export type IconVariantProps = AllowOnlyOne<{ [K in FontAwesome5IconVariants]?: boolean }, FontAwesome5IconVariants>;

export const ItButtonClearWithIcon: React.FC<ButtonClearProps> = ({
  iconName,
  color = colors.primary,
  disabled = false,
  iconSize = 16,
  iconType = 'solid',
  ...props
}: ButtonClearProps) => {
  const iconProps: IconVariantProps = {};
  if (iconType == 'solid') {
    iconProps.solid = true;
  }
  if (iconType == 'light') {
    iconProps.light = true;
  }

  return (
    <Button
      {...props}
      containerStyle={{
        alignItems: 'center',
      }}
      disabled={disabled}
      type="clear"
      icon={
        <FontAwesome5Icon
          {...iconProps}
          name={iconName}
          size={iconSize}
          style={{ marginRight: 7 }}
          color={disabled ? colors.lightGray : color}
        />
      }
    />
  );
};

export const ItButtonClear: React.FC<ButtonProps> = props => (
  <Button
    {...props}
    type="clear"
    titleProps={{
      maxFontSizeMultiplier: 1.3,
    }}
  />
);

export const ItRoundedButton: React.FC<ItButtonProps> = ({ buttonStyle, titleStyle, ...props }: ItButtonProps) => {
  return (
    <ItButton
      {...props}
      titleStyle={[titleStyle, { fontSize: scale(12) }]}
      buttonStyle={[
        buttonStyle,
        {
          borderRadius: scale(28),
          paddingHorizontal: scale(15),
          /* backgroundColor: colors.primary */
        },
      ]}
    />
  );
};

export interface ItButtonProps extends ButtonProps {
  iconName?: string;
}

export const ButtonPrimary: React.FC<ItButtonProps> = props => {
  return (
    <ItButton
      {...props}
      buttonStyle={{
        backgroundColor: colors.primary,
      }}
    />
  );
};

export const ItButton: React.FC<ItButtonProps> = ({
  title,
  disabled = false,
  buttonStyle,
  titleStyle,
  iconName,
  icon,
  type,
  disabledStyle,
  disabledTitleStyle,
  titleProps = {},
  ...props
}: ItButtonProps) => {
  return (
    <Button
      {...props}
      title={title}
      titleStyle={[{ fontSize: moderateScale(14, 0.2) }, titleStyle]}
      buttonStyle={[
        {
          borderRadius: scale(15),
          paddingVertical: moderateScale(13, 0.2),
          paddingHorizontal: moderateScale(28),
          /* backgroundColor: colors.secondary */
        },
        buttonStyle,
      ]}
      /* containerStyle={[
        { marginVertical: 3, marginHorizontal: 3 },
        containerStyle
      ]} */
      type={type}
      disabled={disabled}
      disabledStyle={[
        {
          backgroundColor: colors.secondary,
          opacity: 0.5,
        },
        disabledStyle,
      ]}
      titleProps={{
        ...titleProps,
        maxFontSizeMultiplier: 1.3,
      }}
      disabledTitleStyle={[
        {
          color: colors.white,
        },
        disabledTitleStyle,
      ]}
      icon={
        iconName ? (
          <FontAwesome5Icon
            style={{ marginRight: 7 }}
            size={scale(itGetFontScale() * 15)}
            color="white"
            solid
            name={iconName}
          />
        ) : (
            icon
          )
      }
    />
  );
};

interface ItButtonLongProps extends ButtonProps {
  principal: boolean;
}
/**
 * No usar usar ITButtonLong
 * @deprecated
 */
export const ButtonLong: React.FC<ItButtonLongProps> = ({
  title,
  disabled = false,
  principal = false,
  ...props
}: ItButtonLongProps) => {
  return (
    <ItButton
      {...props}
      title={title}
      titleStyle={{
        fontSize: 17,
        fontWeight: '800',
        textAlign: 'center',
        color: principal ? colors.white : colors.buttonLong,
      }}
      disabledStyle={{
        backgroundColor: principal ? colors.primary : colors.white,
        opacity: 0.5,
      }}
      buttonStyle={[
        {
          borderRadius: 16,
          paddingVertical: 8,
          backgroundColor: principal ? colors.primary : colors.white,
          borderWidth: 1,
          borderColor: colors.buttonLong,
          borderStyle: 'solid',
          marginVertical: 3,
          justifyContent: 'center',
        },
        props.buttonStyle,
      ]}
      containerStyle={[props.containerStyle]}
      type="solid"
      disabled={disabled}
      disabledTitleStyle={{
        color: principal ? colors.white : colors.primary,
      }}
    />
  );
};

export const ItButtonLong = ButtonLong;

export const CardOptionButton: React.FC<ButtonProps> = (props: ButtonProps) => (
  <ItButtonClear {...props} titleStyle={{ fontSize: 14, fontWeight: 'bold' }} />
);

export const ItMenuButtonContainer: React.FC<ViewWithOneOrMultipleChilds> = (props: ViewWithOneOrMultipleChilds) => {
  const { children } = props;
  return (
    <View
      {...props}
      style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}
    >
      {Array.isArray(children)
        ? children.length % 2 == 0
          ? [...children]
          : [...children, <View style={{ flex: 1, minWidth: '49%' }} key="place" />]
        : children}
    </View>
  );
};

interface ItMenuButtonProps extends ItButtonProps {
  count?: number;
}
export const ItMenuButton: React.FC<ItMenuButtonProps> = (props: ItMenuButtonProps) => {
  const fontScale = itGetFontScale();
  const { count = 0 } = props;
  return (
    <View
      style={{
        minWidth: '49%',
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      {count > 0 && (
        <View
          style={{
            backgroundColor: colors.primary,
            width: 17,
            height: 20,
            borderRadius: 10,
            position: 'absolute',
            zIndex: 999,
            right: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItText white style={{ lineHeight: 20 }} size={10} allowFontScaling={false}>
            {count}
          </ItText>
        </View>
      )}

      <ItButton
        {...props}
        title={props.title}
        containerStyle={[props.containerStyle]}
        titleProps={{
          textBreakStrategy: 'highQuality',
          numberOfLines: 1,
          ellipsizeMode: 'tail',
        }}
        buttonStyle={[
          {
            paddingVertical: fontScale * 7,
            paddingHorizontal: fontScale * 16,
            flexWrap: 'nowrap',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: colors.secondary,
          },
          props.buttonStyle,
        ]}
      />
    </View>
  );
};

interface ItDropdownProps {
  buttonProps: ItButtonProps;
  containerProps: ViewProps;
  options: {
    text: string;
    onPress: Function;
    key: string;
  }[];
}

export const ItDropdownButton: React.FC<ItDropdownProps> = (props: ItDropdownProps) => {
  return (
    <View {...props.containerProps}>
      <FontAwesome5Icon
        name="caret-down"
        solid
        size={itGetFontScale() * 14}
        color="white"
        style={{
          position: 'absolute',
          right: 20,
          top: '29%',
          zIndex: 9999,
        }}
      />
      <ItButton {...props.buttonProps} />
      <View
        style={{
          backgroundColor: colors.secondary,
          position: 'absolute',
          top: '100%',
          zIndex: 999,
          elevation: 20,
          marginTop: -3,
          left: 0,
          right: 0,
          alignItems: 'stretch',
          borderRadius: 10,
        }}
      >
        {props.options.map(i => (
          <Touchable onPress={(): void => i.onPress()} key={i.key} style={{ width: '100%' }}>
            <ItText white size={20}>
              {i.text}
            </ItText>
          </Touchable>
        ))}
      </View>
    </View>
  );
};

interface ItMainMenuBarProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  onPress: Function;
}

export const ItMainMenuBar: React.FC<ItMainMenuBarProps> = (props: ItMainMenuBarProps) => {
  return (
    <Touchable
      onPress={(): void => props.onPress()}
      style={{
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 29,
        paddingVertical: 9,
        paddingRight: 28,
        marginBottom: 19,
        shadowColor: '#00000029',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
      }}
    >
      <Block>
        <View
          style={{
            paddingHorizontal: 20,
            borderRightColor: '#878787',
            borderRightWidth: 0.3,
            marginRight: 20,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.icon}
        </View>
        <View style={{ flex: 1 }}>
          <ItText primary strong style={{ marginBottom: 2, fontSize: scale(12), fontWeight: 'bold' }}>
            {props.title}
          </ItText>
          <ItText
            style={{
              color: '#3E3E3E',
              fontSize: scale(12),
            }}
          >
            {props.subtitle}
          </ItText>
        </View>
        <View style={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
          <ChevSvg />
        </View>
      </Block>
    </Touchable>
  );
};
