import * as React from 'react';
import { InputProps, Input } from 'react-native-elements';
import env from 'react-native-config';
import { Platform, StyleSheet } from 'react-native';
import { itS, itVs } from '../libs/resize';
import { TextInputMask } from 'react-native-masked-text'
import * as RNLocalize from 'react-native-localize';


export const ItInput: React.FC<InputProps> = (props: InputProps) => {
  return (
    <Input
      labelStyle={[
        {
          fontSize: itS(12),
          color: '#3F3F3F',
          fontWeight: '400',
          fontFamily: env.MAIN_FONT,
        },
        props.labelStyle,
      ]}
      inputStyle={StyleSheet.flatten([
        {
          paddingHorizontal: 10,
          backgroundColor: 'white',
          width: '100%',
        },
        props.inputStyle
      ])}
      placeholderTextColor="#ccc"
      {...props}
    />
  );
};

export const ItInputBox: React.FC<InputProps> = ({ inputContainerStyle, ...props }: InputProps) => {
  return (
    <ItInput
      labelStyle={{
        fontSize: itS(12),
        color: '#3F3F3F',
        fontWeight: '400',
      }}
      inputStyle={StyleSheet.flatten([
        {
          paddingHorizontal: 10,
          backgroundColor: 'white',
          width: '100%',
          fontSize: itS(16),
          fontFamily: env.MAIN_FONT,
        },
        props.inputStyle
      ])}
      inputContainerStyle={[
        {
          borderWidth: 1,
          borderColor: 'rgba(112, 112, 112, 0.47)',
          paddingVertical: Platform.select({
            web: 0,
            default: itVs(10)
          }),
          marginHorizontal: -10,
        },
        inputContainerStyle,
      ]}
      {...props}
    />
  );
};

export const ItMoneyInputBox = ({ value, onChangeText, ...props }: InputProps) => {
  const { decimalSeparator, groupingSeparator} = RNLocalize.getNumberFormatSettings();
  /* const inputRef = React.useRef<TextInputMasked>(null) */

  return (
    <>
      <TextInputMask
        {...props}
        type={'money'}
        options={{
          precision: 0,
          separator: decimalSeparator,
          delimiter: groupingSeparator,
          unit: '$',
          suffixUnit: ''
        }}
        value={value}
        textContentType="none"
        keyboardType="default"
        customTextInput={ItInputBox}
        includeRawValueInChangeText
        onChangeText={(_, rawText: any) => {
          if (onChangeText) {
            onChangeText(`${rawText}`)
          }
        }}
      />
    </>
  )
}
