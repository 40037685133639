import React from 'react'
import { View } from 'react-native'
import ItText from '../../../components/ItText'
import { itVs, itS } from '../../../libs/resize'

const DcSectionTitle = ({ title }: {title: string}) => {
  return (
    <View style={{
      backgroundColor: '#1B232E',
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: itVs(8),
      borderRadius: itS(5),
      marginBottom: itVs(8),
      paddingHorizontal: 5,
    }}>
      <ItText white strong size={16} style={{ textAlign: 'center'}}>{title}</ItText>
    </View>
  )
}

export default DcSectionTitle
