import * as React from 'react';
import { Text, TextProps, TouchableOpacityProps, TextStyle, StyleSheet, PixelRatio } from 'react-native';
import * as colors from '../theme/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import dayjs from 'dayjs';
import env from 'react-native-config';
import { itS } from '../libs/resize';

export interface ItTextProps extends TextProps {
  children: React.ReactNodeArray | React.ReactNode;
  strong?: boolean;
  secondary?: boolean;
  lightgray?: boolean;
  primary?: boolean;
  white?: boolean;
  underline?: boolean;
  size?: number;
  light?: boolean;
  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
}

interface SectionTitleProps extends ItTextProps {
  small?: boolean;
}

export const SectionTile = ({ style, children, small, size, ...props }: SectionTitleProps): React.ReactElement => (
  <ItText
    style={[
      style,
      {
        color: colors.primary,
        fontSize: small ? 12 : size ? size : 18,
        marginBottom: 7,
      },
    ]}
    {...props}
  >
    {children}
  </ItText>
);

export const SectionTitle = SectionTile;

export const SectionTileCard = ({ style, children, ...props }: ItTextProps): React.ReactElement => (
  <ItText style={[style, { color: colors.black, fontSize: 15, marginBottom: 5 }]} {...props}>
    {children}
  </ItText>
);

export const SectionTitleCard = SectionTileCard;

export const ItTextStrongUnderline = ({ style, children, ...props }: ItTextProps): React.ReactElement => (
  <ItText
    style={[
      style,
      {
        textDecorationLine: 'underline',
        color: colors.primary,
      },
    ]}
    strong
    {...props}
  >
    {children}
  </ItText>
);

interface LinkProps {
  children: React.ReactNode;
  touchableProps: TouchableOpacityProps;
  textStyle?: TextStyle;
}

export const ItLink = ({ children, touchableProps, textStyle }: LinkProps): React.ReactElement => (
  <TouchableOpacity {...touchableProps}>
    <ItText style={[{ textDecorationLine: 'underline', color: colors.primary }, textStyle]}>{children}</ItText>
  </TouchableOpacity>
);

interface ItDateAsText extends Partial<ItTextProps> {
  date: Date | string;
  customFormat?: string;
  name: string;
}
export const ItDateAsText = ({ date, customFormat, name, ...rest }: ItDateAsText): React.ReactElement => {
  return (
    <ItText {...rest}>
      {dayjs(date).format(customFormat ? customFormat : 'dddd, MMMM DD, YYYY')} by {name}
    </ItText>
  );
};

const ItText = ({
  children,
  style,
  strong,
  secondary,
  lightgray,
  primary,
  light,
  white,
  underline,
  fontWeight,
  size,
  ...props
}: ItTextProps): React.ReactElement => {
  return (
    <Text
      adjustsFontSizeToFit
      maxFontSizeMultiplier={1.5}
      style={[
        styles.itText,
        strong && styles.fontBold,
        size ? { fontSize: itS(size) } : {},
        secondary && { color: colors.secondary },
        lightgray && styles.fontLightgray,
        primary && styles.fontPrimary,
        white && styles.fontWhite,
        light && styles.fontLight,
        underline && styles.fontUnderline,
        fontWeight && { fontWeight: fontWeight },
        style,
      ]}
      {...props}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  itText: {
    fontFamily: env.MAIN_FONT,
  },
  fontBold: { fontWeight: 'bold' },
  fontPrimary: { color: colors.primary },
  fontSecondary: { color: colors.secondary },
  fontWhite: { color: 'white' },
  fontLightgray: { color: '#767575' },
  fontUnderline: { textDecorationLine: 'underline' },
  fontLight: { fontWeight: '300' },
  textCenter: { textAlign: 'center' },
});

export default ItText;

export const itGetFontScale = (): number => {
  return PixelRatio.getFontScale() > 1.4 ? 1.4 : PixelRatio.getFontScale();
};
