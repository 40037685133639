import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AsyncStorage from '@callstack/async-storage';

export const getLang = async () => {
  return await AsyncStorage.getItem('lang') || navigator.language;
}

const I18NConfig = async () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        es: {
          translation: {
            "Welcome": "Bienvenido",
            'Error conectando con el servidor': 'Error conectando con el servidor',
            'Ciudad': 'Ciudad',
            'Cargando...': 'Cargando...',
            'Cargando': 'Cargando...',
            'Error conectando con el servidor...': 'Error conectando con el servidor...',
            'Selecciona': 'Selecciona',
            'SIMULADOR DE RENTABILIDAD': 'SIMULADOR DE RENTABILIDAD',
            'Establecimiento': 'Establecimiento',
            'Campo requerido': 'Campo requerido',
            'Subsegmento': 'Segmento',
            'Estrato': 'Estrato',
            'Fuente de Volumen': 'Competencia',
            'Costo': 'Costo',
            'Digite Valor Prod': 'Digite Valor Botella',
            'Precio Venta': 'Precio Venta',
            'Digite Precio Venta': 'Precio Venta Botella',
            'Marca': 'Marca Diageo',
            'Servicio': 'Servicio',
            'CALCULAR': 'CALCULAR',
            'Rentabilidad': 'Rentabilidad',
            'Ganancia con Diageo': 'Ganancia con Diageo',
            'INGREDIENTES': 'INGREDIENTES',
            'SERVICIO': 'SERVICIO',
            'FUENTE VOLUMEN': 'FUENTE VOLUMEN',
            'Diferencia': 'Diferencia',
            'RENTABILIDAD': 'RENTABILIDAD',
            'CALCULO': 'CALCULO',
            'Puede editar los campos que se encuentran en color amarillo': 'Puede editar los campos que se encuentran en color amarillo',
            'Un Medida': 'Un. Medida',
            'Cantidad': 'Cantidad',
            'Valor': 'Valor',
            'Valor Und': 'Valor Und',
            'Primero selecciona una ciudad': 'Primero selecciona una ciudad', 
            'Nombre del establecimiento': 'Nombre del establecimiento',
            'Debes seleccionar una fuente de volumen': 'Debes seleccionar una fuente de volumen',
            'Debes seleccionar una marca': 'Debes seleccionar una marca',
            'Calculando': 'Calculando...',
            'SIMULADOR RENTABILIDAD': 'SIMULADOR RENTABILIDAD:',
            'Ingresando datos importantes como Fuente de Volumen, Marca y Tipo de Servicio, podrás calcular la rentabilidad y ganancia que se obtiene con productos Diageo, por botella y por trago': 'Ingresando datos importantes como Fuente de Volumen, Marca y Tipo de Servicio, podrás calcular la rentabilidad y ganancia que se obtiene con productos Diageo, por botella y por trago.',
            'Siguiente': 'Siguiente',
            'Aquí encuentras la lista de los ingredientes necesarios para preparar el trago o cocktail, en cantidades y propuesta de Valor Unitario.': 'Aquí encuentras la lista de los ingredientes necesarios para preparar el trago o cocktail, en cantidades y propuesta de Valor Unitario.',
            'COSTOS INGREDIENTES': 'COSTOS INGREDIENTES',
            'Listado de ingredientes, con Total Costos del producto y Precio de Venta del servicio.': 'Listado de ingredientes, con Total Costos del producto y Precio de Venta del servicio.',
            'Aquí podrás encontrar el listado de costos, sacando la Rentabilidad del servicio después de impuestos.': 'Aquí podrás encontrar el listado de costos, sacando la Rentabilidad del servicio después de impuestos.',
            'SIGUIENTE': 'SIGUIENTE',
            'Debes ingresar un email': 'Debes ingresar un email',
            'No debe tener espacios en blanco': 'No debe tener espacios en blanco',
            'Usuario o contraseña incorrecto': 'Usuario o contraseña incorrecto',
            'Usuario': 'Usuario',
            'Contraseña': 'Contraseña',
            'INGRESAR': 'INGRESAR',
            'PREPARACIÓN': 'PREPARACIÓN',
            'RECETA': 'RECETA',
            'Total Costos Insumos': 'Total Costos Insumos',
            'Otros Gastos': 'Otros Gastos',
            'Total Costos Producto': 'Total Costos Producto',
            'Precio S Venta': 'Precio S. Venta',
            'ACEPTAR': 'ACEPTAR',
            'INGREDIENTE': 'Ingrediente',
            'TRAGO': 'TRAGO',
            'FUENTE DE VOLUMEN': 'COMPETITOR',
            'OBSERVACIONES': 'OBSERVACIONES',
            'Tipo de comparación': 'Tipo de comparación',
            'Precio Venta Shot': 'Precio Venta trago individual',
            'Precio Venta (Trago o Coctel)': 'Precio Venta (Trago Individual)',
            'Servicio más común': 'Competencia - Servicio más común',
            'Por botella y por trago individual': 'Por botella y por trago individual',
            'Por botella': 'Por botella',
            'Por trago individual': 'Por trago individual',
            'Valor X Unidad': 'Valor X Unidad',
            'Cant ML Botella': 'Cant. ML Botella',
            'Cerrar sesión': 'Cerrar sesión',
            'Escanea el siguiente código con la aplicación Google Authenticator': 'Escanee el siguiente código con la aplicación Google Authenticator',
            'Continuar': 'Continuar',
            'El código no es válido': 'El código no es válido',
            'Ingresa el código que ves en tu aplicación Google Authenticator': 'Ingresa el código que ves en tu aplicación Google Authenticator',
            'Código de Google Authenticator': 'Código de Google Authenticator',
            'VERIFICAR': 'VERIFICAR',
            'Ver más': 'Ver más',
            'Ver menos': 'Ver menos',
            'Para recuperar tu contraseña envíanos un email a la siguiente dirección de correo electrónico': 'Para recuperar tu contraseña envíanos un email a la siguiente dirección de correo electrónico',
            'confirmar número de identidad, nombre y distribuidor y/u organización al a que pertenece.': 'confirmar número de identidad, nombre y distribuidor y/u organización al a que pertenece.',
          }
        },
        pt: {
          translation: {
            "Welcome": "Bienvenido",
            'Error conectando con el servidor': 'Error conectando con el servidor',
            'Ciudad': 'Cidade',
            'Cargando...': 'Carregando...',
            'Cargando': 'Carregando...',
            'Error conectando con el servidor...': 'Erro ao conectar ao servidor...',
            'Selecciona': 'Escolher',
            'SIMULADOR DE RENTABILIDAD': 'SIMULADOR DE LUCRATIVIDADE',
            'Establecimiento': 'Estabelecimiento',
            'Campo requerido': 'Campo obrigatório ',
            'Subsegmento': 'Segmento',
            'Estrato': 'Nivel socioeconômico ',
            'Fuente de Volumen': 'Origem do Volume',
            'Costo': 'Custo',
            'Digite Valor Prod': 'Insira o valor da garrafa',
            'Precio Venta': 'Preço de venda',
            'Digite Precio Venta': 'Insira o preço de venda',
            'Marca': 'Marca Diageo',
            'Servicio': ' Serviço',
            'CALCULAR': 'CALCULAR',
            'Rentabilidad': 'Lucratividade',
            'Ganancia con Diageo': 'Ganho com Diageo',
            'INGREDIENTES': 'INGREDIENTES',
            'SERVICIO': 'SERVIÇO',
            'FUENTE VOLUMEN': 'ORIGEM DO VOLUME',
            'Diferencia': 'Diferença',
            'RENTABILIDAD': 'LUCRATIVIDADE',
            'CALCULO': 'CALCULO',
            'Puede editar los campos que se encuentran en color amarillo': 'Você pode editar os campos que estão em amarelo',
            'Un Medida': 'Un. Medida',
            'Cantidad': 'Quantidade',
            'Valor': 'Valor',
            'Valor Und': 'Valor por Und',
            'Primero selecciona una ciudad': 'Primeiro selecione uma cidade',
            'Nombre del establecimiento': 'Nome da propriedade',
            'Debes seleccionar una fuente de volumen': 'Você deve selecionar uma fonte de volume',
            'Debes seleccionar una marca': 'Você deve selecionar uma fonte de volume',
            'Calculando': 'Calculando...',
            'SIMULADOR RENTABILIDAD': 'SIMULADOR DE LUCRATIVIDADE',
            'Ingresando datos importantes como Fuente de Volumen, Marca y Tipo de Servicio, podrás calcular la rentabilidad y ganancia que se obtiene con productos Diageo, por botella y por trago': 'Inserindo dados importantes, como Origem do Volume, Marca e Tipo de Serviço, você pode calcular a lucratividade e os lucros obtidos com os produtos Diageo, por garrafa e por bebida',
            'Siguiente': 'Próximo',
            'Aquí encuentras la lista de los ingredientes necesarios para preparar el trago o cocktail, en cantidades y propuesta de Valor Unitario.': 'Aqui você encontra a lista dos ingredientes necessários para preparar a bebida ou o coquetel, em quantidades e uma proposta de valor unitário',
            'COSTO DE INGREDIENTES': 'CUSTO DO INGREDIENTE',
            'Listado de ingredientes, con Total Costos del producto y Precio de Venta del servicio.': 'Lista de ingredientes, com custo total do produto e preço de venda do serviço.',
            'Aquí podrás encontrar el listado de costos, sacando la Rentabilidad del servicio después de impuestos.': 'Aqui você encontra a lista de custos, obtendo a lucratividade do serviço após impostos.',
            'SIGUIENTE': 'PRÓXIMO',
            'Debes ingresar un email': 'Você deve inserir um e-mail',
            'No debe tener espacios en blanco': 'Não deve ter espaços em branco',
            'Usuario o contraseña incorrecto': 'Usuário ou senha incorretos',
            'Usuario': 'Usuário',
            'Contraseña': 'Senha',
            'INGRESAR': 'CONECTE-SE',
            'PREPARACIÓN': 'preparação',
            'RECETA': 'RECEITA',
            'Total Costos Insumos': 'Custos totais de entrada',
            'Otros Gastos': 'Outros Gastos',
            'Total Costo Producto': 'Custo total do produto',
            'Precio S Venta': ' S. \'Preço de venda',
            'ACEPTAR': 'ACEITAR',
            'INGREDIENTE': 'INGREDIENTE',
            'TRAGO': 'BEBIDA',
            'FUENTE DE VOLUMEN': 'CONCORRENTE',
            'OBSERVACIONES': 'OBSERVAÇÕES',
            'Tipo de comparación': 'Tipo de comparação',
            'Precio Venta Shot': 'Preço de venda de bebida única',
            'Precio Venta (Trago o Coctel)': 'Preço de venda (bebida ou coquetel)',
            'Servicio más común': 'Concorrência - Serviço mais comum',
            'Por botella y por trago individual': 'Por garrafa e por bebida individual',
            'Por botella': 'Por garrafa',
            'Por trago individual': 'por bebida individual',
            'Valor X Unidad': 'Valor X Unidade',
            'Cant ML Botella': 'Cant. ML Garrafa',
            'Cerrar sesión': 'Fechar Sessão',
            'Escanea el siguiente código con la aplicación Google Authenticator': 'Digitalize o código a seguir com o aplicativo Google Authenticator',
            'Continuar': 'Continuar',
            'El código no es válido': 'O código não é válido',
            'Ingresa el código que ves en tu aplicación Google Authenticator': 'Digite o código que você vê no seu aplicativo Google Authenticator',
            'Código de Google Authenticator': 'Código do Google Authenticator',
            'VERIFICAR': 'VERIFY',
            'Ver más': 'Ver más',
            'Ver menos': 'Ver menos',
            'Para recuperar tu contraseña envíanos un email a la siguiente dirección de correo electrónico': 'Para recuperar sua senha, envie-nos um e-mail para o seguinte endereço de e-mail',
            'confirmar número de identidad, nombre y distribuidor y/u organización al a que pertenece.': 'confirmar número de identidade, número e distribuidor e / ou organização a que pertença.',
          }
        },
        en: {
          translation: {
            "Welcome": "Welcome",
            'Error conectando con el servidor': 'Error connecting to server',
            'Ciudad': 'City',
            'Cargando...': 'Loading...',
            'Cargando': 'Loading...',
            'Error conectando con el servidor...': 'Error connecting to server...',
            'Selecciona': 'Choose',
            'SIMULADOR DE RENTABILIDAD': 'PROFITABILITY SIMULATOR',
            'Establecimiento': 'Venue',
            'Campo requerido': 'Field required',
            'Subsegmento': 'Segmentation',
            'Estrato': 'Socioeconomic level',
            'Fuente de Volumen': 'Source of volume',
            'Costo': 'Cost',
            'Digite Valor Prod': 'Bottle value',
            'Precio Venta': 'Sales Price',
            'Digite Precio Venta': 'Bottle Sale Price',
            'Marca': 'Diageo Brand',
            'Servicio': 'Service',
            'CALCULAR': 'CALCULATE',
            'Rentabilidad': 'Profitability',
            'Ganancia con Diageo': 'Gain with Diageo',
            'INGREDIENTES': 'INGREDIENTS',
            'SERVICIO': 'SERVICE',
            'FUENTE VOLUMEN': 'SOURCE OF VOLUME',
            'Diferencia': 'Difference',
            'RENTABILIDAD': 'PROFITABILITY',
            'CALCULO': 'CALCULATION',
            'Puede editar los campos que se encuentran en color amarillo': 'The fields in yellow can be edited',
            'Un Medida': 'unit of measurement',
            'Cantidad': 'Quantity',
            'Valor': 'Value',
            'Valor Und': 'Value per Unit',
            'Primero selecciona una ciudad': 'Select a city first',
            'Nombre del establecimiento': 'Store name',
            'Debes seleccionar una fuente de volumen': 'You must select a source of volume',
            'Debes seleccionar una marca': 'You must select a brand',
            'Calculando': 'Calculating...',
            'SIMULADOR RENTABILIDAD': 'PROFITABILITY SIMULATOR:',
            'Ingresando datos importantes como Fuente de Volumen, Marca y Tipo de Servicio, podrás calcular la rentabilidad y ganancia que se obtiene con productos Diageo, por botella y por trago': 'By entering important data such as Volume Source, Brand and Type of Service, you can calculate the profitability and profit obtained with Diageo products, per bottle and per drink.',
            'Siguiente': 'Next',
            'Aquí encuentras la lista de los ingredientes necesarios para preparar el trago o cocktail, en cantidades y propuesta de Valor Unitario.': 'Here you find the list of the ingredients necessary to prepare the drink or cocktail, quantities and a suggested unit value.',
            'COSTOS INGREDIENTES': 'INGREDIENT COST',
            'Listado de ingredientes, con Total Costos del producto y Precio de Venta del servicio.': 'List of ingredients, with Total Product Cost and Service Selling Price.',
            'Aquí podrás encontrar el listado de costos, sacando la Rentabilidad del servicio después de impuestos.': 'Here you can find the list of cost, obtaining the Profitability of the service after taxes.',
            'SIGUIENTE': 'NEXT',
            'Debes ingresar un email': 'You must enter an email',
            'No debe tener espacios en blanco': 'No blank spaces',
            'Usuario o contraseña incorrecto': 'Wrong username or password',
            'Usuario': 'User',
            'Contraseña': 'Password',
            'INGRESAR': 'LOGIN',
            'PREPARACIÓN': 'PREPARATION',
            'RECETA': 'RECIPE',
            'Total Costos Insumos': 'Total input cost',
            'Otros Gastos': 'Other Expenses',
            'Total Costos Producto': 'Total Product Cost',
            'Precio S Venta': 'S. Sales Price',
            'ACEPTAR': 'ACCEPT',
            'INGREDIENTE': 'Ingredient',
            'TRAGO': 'DRINK',
            'FUENTE DE VOLUMEN': 'SOURCE OF VOLUME',
            'OBSERVACIONES': 'OBSERVATIONS',
            'Tipo de comparación': 'Comparison Type',
            'Precio Venta Shot': 'Drink Sales Price',
            'Precio Venta (Trago o Coctel)': 'Sales Price (Drink)',
            'Servicio más común': 'Competitor - Serve',
            'Por botella y por trago individual': 'Per bottle and individual drink',
            'Por botella': 'Per bottle',
            'Por trago individual': 'By individual drink',
            'Valor X Unidad': 'Value per Unit',
            '% Costo': '% Cost',
            '% Rentabilidad': '% Profitability',
            'Cant ML Botella': 'Quant. ML Bottle',
            'Cerrar sesión': 'Sign off',
            'Escanea el siguiente código con la aplicación Google Authenticator': 'Scan the following code with the Google Authenticator app',
            'Continuar': 'Continue',
            'El código no es válido': 'The code is not valid',
            'Ingresa el código que ves en tu aplicación Google Authenticator': 'Enter the code you see in your Google Authenticator application',
            'Código de Google Authenticator': 'Google Authenticator Code',
            'VERIFICAR': 'VERIFY',
            'Ver más': 'See more',
            'Ver menos': 'See less',
            'Para recuperar tu contraseña envíanos un email a la siguiente dirección de correo electrónico': 'To retrieve your password, send an email to the following email address',
            'confirmar número de identidad, nombre y distribuidor y/u organización al a que pertenece.': 'confirm ID number, name and distributor and/or organization to which it belongs.',
          }
        },
        
      },
      lng: await AsyncStorage.getItem('lang') || navigator.language,
      fallbackLng: "es",
      interpolation: {
        escapeValue: false
      }
    });
}


export default I18NConfig
