import React from 'react'
import { ScrollView } from 'react-native'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/HomeOutlined';
import IconButton from '@material-ui/core/IconButton';
import DcCalculationTabs from '../components/DcCalculationTabs'
import { ScreenMaxSize } from '../../../components/views'
import ItText from '../../../components/ItText'
import MergeIcon from '../icons/MergeIcon';
import Fab from '@material-ui/core/Fab';
import RecipeOverlay from '../components/RecipeOverlay'
import RentabilityCollapsible from '../components/RentabilityCollapsible'
import { useTranslation } from 'react-i18next'
import PageImgBackground from '../components/PageImgBackground'
import { useSelector } from 'react-redux';
import DCCalculationDetailForm from '../components/DCCalculationDetailForm'
import { selectCalculationResult } from '../diaCalculationState'
import { Card } from 'react-native-elements'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const IngredientsScreen = (props: any) => {
  const classes = useStyles();
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const { t } = useTranslation();
  const data = useSelector(selectCalculationResult);

  return (
    <>
      <AppBar position="fixed" style={{ background: '#2C2C2C' }}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back" onClick={() => {
            props.history.push('/')
          }}>
            <BackIcon></BackIcon>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('CALCULO')}
        </Typography>
        </Toolbar>                              
      </AppBar>
      <ScrollView contentContainerStyle={{ paddingBottom: 70, flex: 1 }} style={{ flex: 1 }}>
        <PageImgBackground>
          <RentabilityCollapsible></RentabilityCollapsible>
          <ScreenMaxSize style={{ marginHorizontal: 20 }}>
            <Card>
              <img src={data.product.brand_image} alt="" style={{ width: '100%', minHeight: 100, backgroundColor: 'lightgray' }} />
              <ItText primary style={{ textAlign: 'center', lineHeight: 18, textTransform: 'uppercase', marginTop: 15 }} strong size={15}>{t('INGREDIENTES')} {data.product.name}</ItText>
              <ItText style={{ textAlign: 'center', color: '#1B232E', marginVertical: 17 }} size={14}>{t('Puede editar los campos que se encuentran en color amarillo')}.</ItText>
              <DCCalculationDetailForm isRecipe></DCCalculationDetailForm>
            </Card>
          </ScreenMaxSize>
        </PageImgBackground>
      </ScrollView>
      <Fab color="secondary" onClick={() => {
        setIsOverlayOpen(true);
      }}>
        <MergeIcon fontSize="large" />
      </Fab>
      <DcCalculationTabs></DcCalculationTabs>
      <RecipeOverlay isVisible={isOverlayOpen} onBackdropPress={() => setIsOverlayOpen(false)}>
      </RecipeOverlay>
    </>
  )
}




export default React.memo(withRouter(IngredientsScreen))
