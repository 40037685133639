import * as React from 'react';
import { ViewProps, View, ScrollViewProps, StyleSheet, ScrollView } from 'react-native';
import * as colors from '../theme/colors';
import { verticalScale } from 'react-native-size-matters';
import { CardProps, Card } from 'react-native-elements';

interface CommonProps extends ViewProps {
  children: React.ReactNode;
}

export interface ViewWithOneOrMultipleChilds extends ViewProps {
  children: React.ReactNode[] | React.ReactNode;
}

interface CardContainerProps extends ViewProps {
  children: React.ReactNode;
  padding?: boolean;
  background?: string;
}

interface BlockProps extends ViewProps {
  children: React.ReactNode;
  padding?: boolean;
  paddingHorizontal?: boolean;
  justifySpaceBetween?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
}

interface ScreenContainerProps extends CommonProps, ScrollViewProps {
  padding?: boolean;
}
export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  children,
  contentContainerStyle,
  ...props
}: ScreenContainerProps) => (
      <ScrollView
        style={{
          flex: 1,
        }}
      contentContainerStyle={[
        contentContainerStyle,
        {
          flexGrow: 1,
          flex: 1,
          backgroundColor: colors.pageBackground,
          maxWidth: 600,
          overflow: 'scroll',
          width: '100%',
          alignSelf: 'center',
        },
      ]}
        {...props}>
        {children}
      </ScrollView>
  );

export const ScreenMaxSize = (props: any) => <View style={[{ maxWidth: 600, width: '100%', alignSelf: 'center' }, props.style]}>{props.children}</View>

export const JustifyCenterContainer: React.FC<CommonProps> = ({ style, children, ...props }: CommonProps) => (
  <View style={[style, { justifyContent: 'center', alignItems: 'center' }]} {...props}>
    {children}
  </View>
);

export const InputContainerView: React.FC<CommonProps> = ({ style, children, ...props }: CommonProps) => (
  <View style={[style, { marginBottom: verticalScale(20), position: 'relative', zIndex: 999 }]} {...props}>
    {children}
  </View>
);

export const CardContainer: React.FC<CardContainerProps> = ({
  style,
  children,
  padding,
  background,
  ...props
}: CardContainerProps) => {
  return (
    <View
      style={[
        {
          backgroundColor: background || 'white',
          width: '100%',
          padding: 15,
        },
        padding && {
          padding: 16,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

export const Block: React.FC<BlockProps> = (props: BlockProps) => {
  const {
    padding,
    justifyCenter,
    justifySpaceBetween,
    alignCenter,
    paddingHorizontal,
    justifyEnd,
    children,
    style,
    ...restProps
  } = props;
  return (
    <View
      style={[
        styles.block,
        padding && styles.padding,
        justifySpaceBetween && styles.justifySpaceBetween,
        alignCenter && styles.alignCenter,
        paddingHorizontal && styles.paddingHorizontal,
        justifyCenter && styles.justifyCenter,
        justifyEnd && styles.justifyEnd,
        style,
      ]}
      {...restProps}
    >
      {children}
    </View>
  );
};

export const ScreenContainerJobs: React.FC<BlockProps> = (props: BlockProps) => {
  return <View style={styles.containterJobs}>{props.children}</View>;
};

export const BlueBlock: React.FC<BlockProps> = (props: BlockProps) => <Block style={styles.blueBlock} {...props} />;

export const SeparatorList: React.FC = () => <View style={styles.separator} />;

export const ItCard: React.FC<CardProps> = props => <Card {...props} />;
export const MaxWidthDesktop = (props: any) => <View {...props} style={[{ maxWidth: 1000 }, props.style]}></View>;

const styles = StyleSheet.create({
  block: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding: {
    padding: 20,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'lightgray',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  blueBlock: {
    backgroundColor: '#E3F5FF',
  },
  alignCenter: {
    alignItems: 'center',
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  containterJobs: {
    backgroundColor: colors.pageBackground,
    padding: 15,
  },
});


export const ElevatedCard = (props: any) => { 
  return (
    <View
      style={[{
        backgroundColor: 'white',
        borderRadius: 5,
        shadowColor: "rgba(0,0,0,0.3)",
        shadowOffset: {
          width: 3,
          height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 3,
        
        elevation: 12,
        marginVertical: 5,
        overflow: 'hidden',

        borderColor: '#9997A2',
        borderWidth: 0.45,
      }, props.style]}
    >{props.children}</View>
  )
}