import React from 'react'
import { View } from 'react-native'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ItText from '../../../components/ItText';
import { Block } from '../../../components/views';
import { ItButton } from '../../../components/buttons';
import { Divider } from 'react-native-elements';
import { primary } from '../../../theme/colors';
import { useSelector } from 'react-redux';
import { selectCalculationResult } from '../diaCalculationState';
import { useTranslation } from 'react-i18next';



const RecipeOverlay = ({ isVisible, onBackdropPress }: any) => {
  const data = useSelector(selectCalculationResult);
  const { t } = useTranslation()

  return (
    <Dialog
      open={isVisible}
      onClose={onBackdropPress}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ width: '100%', padding: 0 }}>
        <div dangerouslySetInnerHTML={{ __html: data.product.video }} style={{
          width: '100%',
          backgroundColor: 'lightgray'
        }}></div>
        <View style={{ padding: 20, alignItems: 'center' }}>
          <Block>
            <ItText primary style={{ textAlign: 'center' }}>{t('PREPARACIÓN')}</ItText>
          </Block>
            <Divider style={{ width: 60, backgroundColor: primary, marginTop: 10, marginBottom: 15 }}></Divider>
          <View>
            <ItText lightgray>
              {data.product.preparation}
            </ItText>
            <ItButton title={t('ACEPTAR')} containerStyle={{ marginTop: 20 }} onPress={() => { 
              onBackdropPress()
            }}></ItButton>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  )
}

export default RecipeOverlay
