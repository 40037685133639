import * as React from "react";
import "./App.css";
/* import * as RNCore from "@react-navigation/core";
import * as RNWeb from "@react-navigation/web"; */
import { ThemeProvider } from "react-native-elements";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./theme";
/* import AuthNavigator from "./modules/auth/AuthNavigator"; */
import AuthLoadingScreen from "./modules/auth/AuthLoadingScreen";
import loadFontAwesomeProWeb from "./loadFontAwesomeProWeb";
import { Provider } from "react-redux";
import persistor, { store } from "./store";
import { ThemeProvider as MuThemeProvider } from '@material-ui/core/styles';

/* import AppNavigator from "./appNavigator";
import CalculationTabNavigator from "./modules/diaCalculation/CalculationTabNavigator"; */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  /* Link */
} from "react-router-dom";
import Loader from "./components/Loader";
import muTheme from "./theme/muTheme";
import './App.css';
import VolumeSource from "./modules/diaCalculation/screens/VolumeSource";
import IngredientsDetail from "./modules/diaCalculation/screens/IngredientsDetail";
import I18NConfig from "./i18n/I18NConfig";

const WelcomeScreen = React.lazy(() => import("./modules/mainMenu/WelcomeScreen"));
const IntroScreen = React.lazy(() => import("./modules/auth/IntroScreen"));
const LoginScreen = React.lazy(() => import('./modules/auth/screens/LoginScreen'));
const IngredientsScreen = React.lazy(() => import("./modules/diaCalculation/screens/IngredientsScreen"));
const CompleteProfileScreen = React.lazy(() => import("./modules/auth/CompleteProfileScreen"));
const ServiceScreen = React.lazy(() => import("./modules/diaCalculation/screens/ServiceScreen"));

loadFontAwesomeProWeb();


/* const Navigator = RNWeb.createBrowserApp(
  RNCore.createSwitchNavigator(
    {
      AuthLoading: AuthLoadingScreen,
      AppNavigator: {
        screen: AppNavigator,
        path: 'app'
      },
      IntroScreen: {
        screen: IntroScreen,
        path: 'tour'
      },
      AuthNavigator: {
        screen: AuthNavigator,
        path: 'autenticar',
      },
      CalculationTabNavigator: {
        screen: CalculationTabNavigator,
        navigationOptions: {
          cardStyle: {
            minHeight: '100vh',
          },
        },
      }
    },
    {
      initialRouteName: "AuthLoading",
      initialRouteParams: {
        introEnabled: true,
      },
      navigationOptions: {
        cardStyle: {
          flex: 1,
        },
      },
    }
  ),
  {
    history: "browser"
  }
); */

I18NConfig();


const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuThemeProvider theme={muTheme}>
            <Router>
              <React.Suspense fallback={() => <Loader loading></Loader>}>
                <Switch>
                  <Route path="/" exact component={AuthLoadingScreen}>
                  </Route>
                  <Route path="/welcome">
                    <WelcomeScreen></WelcomeScreen>
                  </Route>
                  <Route path="/login">
                    <LoginScreen></LoginScreen>
                  </Route>
                  <Route path="/intro">
                    <IntroScreen />
                  </Route>
                  <Route path="/complete-profile">
                    <CompleteProfileScreen />
                  </Route>
                  <Route path="/calculation-result">
                    <IngredientsScreen></IngredientsScreen>
                  </Route>
                  <Route path="/service-result">
                    <ServiceScreen></ServiceScreen>
                  </Route>
                  <Route path="/volume-source">
                    <VolumeSource></VolumeSource>
                  </Route>
                  <Route path="/ingredients-detail">
                    <IngredientsDetail></IngredientsDetail>
                  </Route>
                </Switch>
              </React.Suspense>
            </Router>
          </MuThemeProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
