import React from 'react'
import DcPrice from './DcPrice';
import { useField } from 'formik';

const DCTotalIngredientPrice = ({ index }: { index: string }) => {
  const [{ value: quantity }] = useField(`ingredients[${index}].quantity`);
  const [{ value: price_unit }] = useField(`ingredients[${index}].price_unit`);

  return (
    <DcPrice
      strong
      containerStyle={{ flex: 1 }}
      value={quantity * price_unit}
    ></DcPrice>
  )
}

export default React.memo(DCTotalIngredientPrice); 