import React, { ReactElement } from 'react'

const PageImgBackground = (props: { children: ReactElement | ReactElement[], style?: any }) => {
  const { style = {} } = props;
  return (
    <div style={{
      backgroundImage: `url(${require('../assets/pattern.svg')})`,
      backgroundRepeat: 'repeat',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: 60,
      ...style
    }}>{props.children}</div>
  )
}

export default PageImgBackground
