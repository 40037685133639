import { combineReducers } from "redux";
import { userReducer } from "../modules/auth/AuthState";
import { diaCalculationReducer } from "../modules/diaCalculation/diaCalculationState";

const rootReducer = combineReducers({
  user: userReducer,
  diaCalculation: diaCalculationReducer
});

export default rootReducer;
