import * as React from "react";
import {
  View,
  ActivityIndicator,
  Text,
  StyleSheet,
  Platform
} from "react-native";
import { withTheme, ThemeProps, Theme } from "react-native-elements";
import AsyncStorage from "@callstack/async-storage";
import {Redirect} from 'react-router-dom';

const containerPlatformStyles = Platform.select({
  web: {
    height: "100vh"
  },
  default: {
    flex: 1
  }
});

interface Props extends ThemeProps<any> {
  theme: Theme<any>;
}

const AuthLoadingScreen = (props: Props) => {
  const [url, setUrl] = React.useState<string | null>(null)
  React.useEffect(() => {
    const verifyAuth = async () => {
      const token = await AsyncStorage.getItem("token");
      /* const introEnabled = props.navigation.getParam("introEnabled", false); */
      if (token) {
        setUrl('/welcome');
        return;
      }
      const tourCompleted = await AsyncStorage.getItem("tour_completed");
      setUrl(tourCompleted == "1" ? "/login" : "/intro");
      /* props.navigation.navigate(
        
      ); */
    };
    verifyAuth();
  }, []);

  if (url !== null) {
    return (
      <Redirect to={url}></Redirect>
    )
  }
  return (
    <View style={containerPlatformStyles}>
      <View style={styles.container}>
        <ActivityIndicator
          color={props.theme.colors.primary}
          style={styles.activity}
        ></ActivityIndicator>
        <Text style={styles.loadingText}>Cargando...</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  activity: {
    marginLeft: 10
  },
  loadingText: {
    marginLeft: 10,
    fontSize: 18
  }
});

export default withTheme(AuthLoadingScreen);
