import React from 'react'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IngredientsIcon from '../icons/IngredientsIcon';
import ComponenteComponent from '../icons/ServiceIcon';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCalculationResult } from '../diaCalculationState';


const DcCalculationTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const data = useSelector(selectCalculationResult);

  const routes = ['/calculation-result', '/ingredients-detail', '/service-result', '/volume-source']

  const handleChange = (_: any, newValue: any) => {
    history.replace(routes[newValue])
  };


  return (
    <Paper square>
      <Tabs
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          backgroundColor: 'white',
          boxShadow: '0 -2px 2px rgba(0,0,0,0.2)',
        }}
        value={routes.indexOf(location.pathname)}
        variant="fullWidth"
        onChange={handleChange}
        indicatorColor="white"
        textColor="primary"
        color="red"
        aria-label="icon label tabs example"
      >
        <Tab label={t('CALCULO')} icon={<FontAwesome5Icon name="calculator" size={23} light color="inherit" ></FontAwesome5Icon>} />
        <Tab disabled={data.type_result == "Bottle"} label={t('INGREDIENTES')} icon={<IngredientsIcon style={{ fontSize: 26 }}></IngredientsIcon>} />
        <Tab disabled={data.type_result == "Bottle"} label={t('SERVICIO')} icon={<ComponenteComponent style={{ fontSize: 20 }}></ComponenteComponent>} />
        <Tab label={t('FUENTE VOLUMEN')} icon={<FontAwesome5Icon name="bolt" size={23} light color="inherit" ></FontAwesome5Icon>} />
      </Tabs>
    </Paper>
  )
}

export default DcCalculationTabs
