import { Action } from "redux";

export const SET_CALCULATION_RESULT = 'SET_CALCULATION_RESULT';



export const diaCalculationReducer = (state = {}, action: Action & { result?: any }) => { 
  if (action.type === SET_CALCULATION_RESULT) { 
    return {
      ...state,
      calculationResult: action.result,
    }
  }
  return state;
}

export const selectCalculationResult = (state: any) => { 
  return state.diaCalculation.calculationResult;
}