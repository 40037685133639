export const primary: string = "#8B1A17";
export const primaryDark: string = "#00386A";
export const primaryDarkAlt: string = "#242134";
export const secondary: string = "#1B232E";
export const secondaryDark: string = "#1B232E";
export const pageBackground: string = "#F2F3F7";
export const third: string = "#AE661D";
export const lightGray: string = "#676767";
export const lightGrayAlt: string = "#ccc";
export const black = '#000000';
export const rejected = '#C13737';
export const approved = '#007A0C';
export const lightText = '#414141';
export const pending = '#0033cc';
export const white = '#FFFFFF';
export const fourth = '#00325E';
export const contentBackground = '#E3F5FF';
export const infoSecondary = '#9A9A9A';
export const titles = '#113C72';
export const sectionThird = '#E5EFF4';
// @deprecated
export const buttonLong: string = primary;
