import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@callstack/async-storage';
import thunk, { ThunkAction } from 'redux-thunk';
import reducers from './reducers';
import { createStore, compose, applyMiddleware, Action } from 'redux';

interface Country {
  id: 47;
  iso: 'CO';
}
interface Company {
  id: number;
  alias: string;
  logo: string;
  name: string;
  backgroundcolor: string;
  textcolor: string;
}

export interface ItUser {
  login: string;
  it_business_id: number;
  id: number;
  it_branches_id: number;
  email: string;
  name: string;
  last_name: string;
  can_notify: 'N' | 'Y';
  phone: null | string;
  country_phone: string;
  it_countries_id: number;
  birthday: null | string;
  session_type: 'M' | 'S';
  avatar: string;
  status: 'A' | 'S';
  it_profile_id: number;
  languaje: 'ES' | 'EN';
  first_login: string;
  last_login: string;
  it_positions_id: number;
  created_at: null | string;
  updated_at: null | string;
  deleted_at: null | string;
  company: Company[];
  can_change_position: 'S' | 'N';
  country: Country[];
  block_admin: boolean;
  card_url: string;
  account_status: 'Active' | 'Suspended';
  qr: string;
}


export interface ItAppState {
  user: {
    info: ItUser;
  };
}

export type ItThunkAction = ThunkAction<Promise<void>, ItAppState, undefined, Action>;

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  {},
  compose(
    applyMiddleware(thunk),
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ),
);
const persistor = persistStore(store);
export default persistor;
