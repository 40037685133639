import React from 'react'
import { View, Image } from 'react-native'
import { Block, ElevatedCard } from '../../../components/views'
import { itS } from '../../../libs/resize'
import ItText from '../../../components/ItText'

const DcPrice = (props: {
  iconBackgroundColor?: string,
  icon?: any,
  value: number;
  textStyle?: any,
  strong?: boolean,
  containerStyle?: any
}) => {
  var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return (
    <ElevatedCard style={[props.containerStyle, { padding: 0, justifyContent: 'center', flex: 1 }]}>
      <Block>
        {props.icon && <View style={{
          justifyContent: 'center',
          backgroundColor: props.iconBackgroundColor,
          width: itS(52),
          height: itS(45),
          alignItems: 'center',
        }}>
          <Image style={{ width: itS(26), height: itS(22) }} resizeMode="contain" source={props.icon} ></Image>
        </View>}
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ItText strong={props.strong} size={16} style={props.textStyle}>{formatter.format(props.value)}</ItText>
        </View>
      </Block>
    </ElevatedCard>
  )
}

export default DcPrice
