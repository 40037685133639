import React from 'react'
import { Block } from '../../../components/views'
import { EcValuePalid } from './DcUi'
import DcPrice from './DcPrice'

const DCTaxesCard = ({ tax }: {tax: any}) => {
  return (
    <Block>
      <EcValuePalid text={tax.tax.name} style={{
        marginRight: 11,
      }}></EcValuePalid>
      <DcPrice
        containerStyle={{ flex: 1 }}
        value={tax.price}
      ></DcPrice>
    </Block>
  )
}

export default DCTaxesCard
