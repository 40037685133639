import React from 'react'
import DcSectionTitle from './DcSectionTitle'
import { Block } from '../../../components/views'
import { LightgrayBox, YellowInput } from './DcUi'
import ECValue from './ECValue'
import { useTranslation } from 'react-i18next';
import { FastField, useField } from 'formik'
import { View } from 'react-native'
import DcPrice from './DcPrice'

const IngredientEditableItem = ({ ingredient, index, isDiageoIngredient }: { ingredient: any, index: string, isDiageoIngredient: boolean }) => {
  const { t } = useTranslation()
  const [field] = useField(`ingredients[${index}]`)

  return (
    <View style={{ marginBottom: 20 }}>
      <DcSectionTitle title={ingredient.name}></DcSectionTitle>
      <Block>
        <LightgrayBox text={t('Un Medida')} />
        <ECValue text={ingredient.measurement_unit.abbreviation} />
      </Block>
      <Block>
        <LightgrayBox text={t('Cantidad')} />
        <FastField name={`ingredients[${index}].purchase_quantity`}>
          {({ field, form, meta }: any) => (
            <YellowInput
              value={field.value}
              onChangeText={nv => form.setFieldValue(field.name, nv)}
              onBlur={() => form.setFieldTouched(field.name, true)}
              // @ts-ignore
              errorMessage={meta.touched ? meta.error : undefined}
            />
          )}
        </FastField>
      </Block>
      <>
        {!isDiageoIngredient && (
          <Block>
            <LightgrayBox text={t('Costo')} />
            <FastField name={`ingredients[${index}].purchase_price`}>
              {({ field, form, meta }: any) => (
                <YellowInput
                  value={field.value}
                  onChangeText={nv => form.setFieldValue(field.name, nv)}
                  onBlur={() => form.setFieldTouched(field.name, true)}
                  // @ts-ignore
                  errorMessage={meta.touched ? meta.error : undefined}
                />
              )}
            </FastField>
          </Block>
        )}
        <Block>
          <LightgrayBox text={t('Valor X Unidad')} />
          <DcPrice
            value={field.value.purchase_price / field.value.purchase_quantity}
          ></DcPrice>
        </Block>
      </>
    </View>
  )
}

export default React.memo(IngredientEditableItem)
