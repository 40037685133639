// @ts-ignore
import iconFontSolid from "./assets/fonts/FontAwesome5_Pro_Solid.ttf";
// @ts-ignore
import iconFontRegular from "./assets/fonts/FontAwesome5_Pro_Regular.ttf";
// @ts-ignore
import iconFontLight from "./assets/fonts/FontAwesome5_Pro_Light.ttf";

const loadFontAwesomeProWeb = () => {
  const iconFontStyles = `
  @font-face {
    src: url(${iconFontSolid});
    font-family: FontAwesome5_Pro_Solid;
  }
  @font-face {
    src: url(${iconFontRegular});
    font-family: FontAwesome5_Pro_Regular;
  }
  @font-face {
    src: url(${iconFontLight});
    font-family: FontAwesome5_Pro_Light;
  }
  `;

  // Create stylesheet
  const style = document.createElement("style");
  style.type = "text/css";
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
};

export default loadFontAwesomeProWeb;
