import React from "react";
import { SvgIcon } from "@material-ui/core";

const IngredientsIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 37.755 29.221">
    <defs>
      <style>{".a{fill:inherit;}"}</style>
    </defs>
    <g transform="translate(13.715)">
      <g transform="translate(0)">
        <path
          className="a"
          d="M136.97,85.483h-7.828l0-.667V70.806l.023-.088a3.534,3.534,0,0,1,2.185-2.3l-.011-6.738-.178-.2-.005-1.889,4.413-.011,0,1.908-.178.179.012,6.737a3.533,3.533,0,0,1,2.194,2.281l.025.088v.1l.011,14.606Zm-6.5-1.33h5.836l-.01-13.171a2.1,2.1,0,0,0-1.642-1.381l-.576-.077-.014-8.379.177-.178v-.048l-1.754,0,.006.055.032.038.14.18v.231l.014,8.1-.574.08A2.1,2.1,0,0,0,130.471,71Z"
          transform="translate(-129.141 -59.586)"
        />
      </g>
    </g>
    <g transform="translate(0 25.232)">
      <g transform="translate(0 2.659)">
        <rect className="a" width={37.755} height={1.33} />
      </g>
      <g transform="translate(0)">
        <rect className="a" width={37.755} height={1.33} />
      </g>
    </g>
    <g transform="translate(14.38 14.345)">
      <rect
        className="a"
        width={7.166}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(14.38 20.412)">
      <rect
        className="a"
        width={7.166}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(23.631 6.866)">
      <path
        className="a"
        d="M200.263,120.588H189.792a1,1,0,0,1-1-1v-9.557a1.278,1.278,0,0,1,.635-1.075l3.453-1.9.22-5.195a1.022,1.022,0,0,1,1.011-.969h1.828a1.022,1.022,0,0,1,1.011.969l.219,5.195,3.454,1.9a1.278,1.278,0,0,1,.635,1.075v9.557A1,1,0,0,1,200.263,120.588Zm-10.139-1.33h9.806v-9.164l-3.437-1.894a1.316,1.316,0,0,1-.649-1.047l-.208-4.932h-1.218l-.208,4.932a1.316,1.316,0,0,1-.649,1.047l-3.437,1.894Z"
        transform="translate(-188.795 -100.892)"
      />
    </g>
    <g transform="translate(27.37 5.01)">
      <path
        className="a"
        d="M215.614,92.912H211.3l0-3.184.667,0h4.318l0,3.184Zm-2.99-1.33h2.327v-.526h-2.327Z"
        transform="translate(-211.294 -89.726)"
      />
    </g>
    <g transform="translate(24.295 17.42)">
      <rect
        className="a"
        width={11.136}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(24.295 21.741)">
      <rect
        className="a"
        width={11.136}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(26.996 19.581)">
      <rect className="a" width={1.33} height={1.33} transform="translate(0)" />
    </g>
    <g transform="translate(29.198 19.581)">
      <rect className="a" width={1.33} height={1.33} />
    </g>
    <g transform="translate(31.401 19.581)">
      <rect className="a" width={1.33} height={1.33} transform="translate(0)" />
    </g>
    <g transform="translate(4.812 4.995)">
      <path
        className="a"
        d="M79.309,92.716H75.578l0-3.073,4.4,0,0,3.073Zm-2.4-1.33h1.74v-.416h-1.74Z"
        transform="translate(-75.576 -89.641)"
      />
    </g>
    <g transform="translate(2.277 6.741)">
      <path
        className="a"
        d="M68.629,119.962h-8.3l0-.667V104.689l3.158,0v-4.545l3.151,0,0,.667v3.88h2.658l0,.667V119.96Zm-6.974-1.33h6.311V106.017H65.308v-4.546h-.493v4.545l-3.158,0Z"
        transform="translate(-60.325 -100.141)"
      />
    </g>
    <g transform="translate(2.942 13.348)">
      <rect
        className="a"
        width={7.641}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(2.942 22.905)">
      <rect
        className="a"
        width={7.641}
        height={1.33}
        transform="translate(0 0)"
      />
    </g>
    <g transform="translate(4.313 16.301)">
      <path
        className="a"
        d="M75.024,162.556a2.449,2.449,0,1,1,2.449-2.449A2.452,2.452,0,0,1,75.024,162.556Zm0-3.568a1.119,1.119,0,1,0,1.119,1.119A1.121,1.121,0,0,0,75.024,158.988Z"
        transform="translate(-72.575 -157.658)"
      />
    </g>
  </SvgIcon>
);

export default IngredientsIcon;
