import { Theme } from "react-native-elements";
import { Dimensions, ViewStyle } from "react-native";
import { primary } from "./theme/colors";

const { width } = Dimensions.get("screen");
const navbarHeight = 91;
const navbarPlatformStyles =
  width > 600
    ? {
        height: navbarHeight,
        paddingStart: 130,
        paddingEnd: 130
      }
    : {};
const navbarLogoAspectRelation = 130 / 49;
const navbarLogoHeight = width > 600 ? 49 : 40;
const navbarLogoWidth = navbarLogoHeight * navbarLogoAspectRelation;

export type ItTheme = Theme<{
  navbarLogoHeight: number;
  navbarLogoWidth: number;
  navbarPlatformStyles: ViewStyle;
  navbarHeight: number;
}>;

const theme: ItTheme = {
  colors: {
    primary
  },
  Button: {
    titleStyle: {
      fontWeight: "600"
    }
  },
  navbarHeight,
  navbarLogoHeight,
  navbarLogoWidth,
  navbarPlatformStyles
};

export default theme;
