import React from 'react'
import { View } from 'react-native';
import CollapsibleBlue from './CollapsibleBlue'
import ItText from '../../../components/ItText'
import { Block } from '../../../components/views'
import { EcValuePalid } from './DcUi';
import DcPercent from './DcPercent';
import DcPrice from './DcPrice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCalculationResult } from '../diaCalculationState';

const RentabilityCollapsible = () => {
  const data = useSelector(selectCalculationResult);
  const { t } = useTranslation();
  const resume = data.profability.product_diageo.profability_coctail_detail;
  const pd_profabilityBottle = data?.profability?.product_diageo?.profability_bottle_detail?.profitability_bottle
  const vs_profabilityBottle = data?.profability?.volume_source?.profability_detail?.profitability_bottle
  const pd_profabilityShot = resume.rentability_coctail;
  const vs_profabilityShot = data?.profability?.volume_source?.profability_detail?.profitability_shots
  
  const percent = ((pd_profabilityBottle - vs_profabilityBottle) / pd_profabilityBottle) * 100
  const percentShot = ((pd_profabilityShot - vs_profabilityShot) / pd_profabilityShot) * 100
  const diffBottle = data?.profability?.product_diageo?.profability_bottle_detail?.profitability_bottle - data?.profability?.volume_source?.profability_detail?.profitability_bottle;
  const diffShot = pd_profabilityShot - vs_profabilityShot

  const enableBottle = data.type_result == 'Bottle' || data.type_result == 'Both';
  const enableShot = data.type_result == 'Shot' || data.type_result == 'Both';

  return (
    <CollapsibleBlue
      header={(
        <Block justifyCenter style={{
          paddingTop: 15,
          paddingBottom: 10,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <View style={{ backgroundColor: '#A3FFB9', width: 22, height: 22, marginRight: 10, borderRadius: 11 }}>
          </View>
          <ItText strong size={14}>{t('RENTABILIDAD')}</ItText>
        </Block>
      )}
      content={(
        <View style={{
          justifyContent: 'center',
          alignSelf: 'center',
          width: 300,
          marginBottom: 20
        }}>
          <Block>
            <View style={{ paddingLeft: 52, width: 'calc(50% - 5px)', marginRight: 11 }}>
              <EcValuePalid text="%" style={{ justifyContent: 'center', alignItems: 'center' }} />
            </View>
            <EcValuePalid text={t('Diferencia')} style={{ justifyContent: 'center', alignItems: 'center' }} />
          </Block>
          {enableBottle &&<Block>
            <DcPercent
              iconBackgroundColor="#0F437F"
              containerStyle={{
                marginRight: 11
              }}
              icon={require('../assets/bottle.png')}
              value={percent}
            />
            <DcPrice
              value={diffBottle}
            ></DcPrice>
          </Block>}
          {enableShot && <Block>
            <DcPercent
              iconBackgroundColor="#AE661D"
              containerStyle={{
                marginRight: 11
              }}
              icon={require('../assets/glass.png')}
              value={percentShot}
            ></DcPercent>
            <DcPrice
              value={diffShot}
            ></DcPrice>
          </Block>}
        </View>
      )}></CollapsibleBlue>
  )
}

export default RentabilityCollapsible
