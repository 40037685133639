import React from 'react';
import { ElevatedCard } from '../../../components/views';
import ItText from '../../../components/ItText';
import { ViewStyle, TextStyle } from 'react-native';

const ECValue = ({ text, style, textStyle }: { text: any, style?: ViewStyle, textStyle?: TextStyle}) => {
  return (
    <ElevatedCard style={[{ flex: 1, minHeight: 45, justifyContent: 'center', alignItems: 'center' }, style]}>
      <ItText size={16} style={[{ color: '#1B232E' }, textStyle]}>{text}</ItText>
    </ElevatedCard>
  )
}

export default ECValue;