import React from "react";
import ReactDOM from 'react-dom';
import { View, ActivityIndicator, Dimensions } from "react-native";
import ItText from "./ItText";
/* import { Overlay } from "react-native-elements"; */

const { height } = Dimensions.get('screen');
interface Props {
  loading: boolean;
  message?: string;
}
const Loader = (props: Props) => {
  const { message, loading } = props;
  if (!loading) {
    return null;
  }
  return ReactDOM.createPortal(
    <div
      style={{
        zIndex: 9999,
        backgroundColor: "rgba(0,0,0,0.5)",
        position: 'fixed',
        top: 0,
        bottom: 0,
        display: 'flex',
        right: 0,
        left: 0,
        justifyContent: "center",
        height,
        alignItems: "center"
      }}
    >
      <View
        style={{
          backgroundColor: "white",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          borderRadius: 15
        }}
      >
        <View>
          <ItText style={{ fontSize: 17, marginBottom: 10 }}>{message}</ItText>
        </View>
        <View>
          <ActivityIndicator size="large" color="#000000"></ActivityIndicator>
        </View>
      </View>
    </div>
    , (document.getElementById('overlay') as Element));
};
export default Loader;
