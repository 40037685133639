/* import { vs, s } from 'react-native-size-matters'; */
/* import { Dimensions } from 'react-native'; */

/* const { width } = Dimensions.get('screen'); */

/* const baseScale = width > 600 ? (width - 600) / 600 : 0;
const baseVScale = width > 600 ? (width - 600) / 600 : 0; */

export const itVs = (size: number) => { 
  //return vs(size) - (baseVScale * size);
  return size;
}

export const itS = (size: number) => { 
/* return s(size) - (baseScale * size); */
  return size
}