import React from 'react';
import { InputProps } from 'react-native-elements';
import ECValue from './ECValue';
import { ItInputBox } from '../../../components/input';

export const YellowInput = ({ errorMessage, ...props }: InputProps) => {
  return (
    <ECValue
      text={
        <ItInputBox
          {...props}
          inputStyle={[{ width: '100%', textAlign: 'center' }, props.inputStyle]}
          inputContainerStyle={{ borderColor: 'transparent', marginHorizontal: 0, width: '100%' }}
        />
      }
      style={{ paddingVertical: 0 }}
      textStyle={{
        width: '100%',
        backgroundColor: errorMessage ? 'rgba(255, 0, 87, 0.37)' : '#FAF9D8'
      }}
      />
  )
}

export const LightgrayBox = ({ text }: { text: string }) => {
  return <ECValue text={text} style={{ backgroundColor: '#9997A2', marginRight: 10, alignItems: 'flex-start' }} textStyle={{ color: 'white', textAlign: 'left', paddingHorizontal: 20 }} />
}

export const EcValuePalid = (props: any) => { 
  return <ECValue {...props} style={[{
    backgroundColor: '#F1F3F2', alignItems: 'flex-start'
  }, props.style]} textStyle={[{ paddingHorizontal: 20 }, props.textStyle]}/>
}

