import React from "react";
import { SvgIcon } from "@material-ui/core";

const MergeIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 48.152 48.152">
      <defs>
        <style>{".a{fill:inherit;}"}</style>
      </defs>
      <path
        className="a"
        d="M641.7,604.03a2.888,2.888,0,0,1-2.038-.849l-5.187-5.187a2.861,2.861,0,0,1-.674-3.009c4.86-13.037,16.329-22.74,16.815-23.147a.819.819,0,0,1,1.105.049l14.048,14.048a.82.82,0,0,1,.049,1.106c-.407.486-10.11,11.955-23.147,16.815A2.783,2.783,0,0,1,641.7,604.03Zm9.405-30.441c-2.379,2.136-11.664,10.957-15.769,21.968a1.218,1.218,0,0,0,.3,1.278l5.187,5.187a1.214,1.214,0,0,0,1.278.3c11.012-4.1,19.833-13.39,21.969-15.769Z"
        transform="translate(-628.195 -561.307)"
      />
      <path
        className="a"
        d="M919.3,872.13a.819.819,0,0,1-.712-1.224,38.957,38.957,0,0,1,4.045-5.761.819.819,0,1,1,1.256,1.053,37.362,37.362,0,0,0-3.875,5.517A.819.819,0,0,1,919.3,872.13Z"
        transform="translate(-904.11 -845.313)"
      />
      <path
        className="a"
        d="M1147.595,467.656a1.575,1.575,0,0,1-1.116-.461l-15.839-15.839a1.582,1.582,0,0,1,0-2.233l1.722-1.722a1.582,1.582,0,0,1,2.233,0l15.839,15.839a1.582,1.582,0,0,1,0,2.233l-1.722,1.722A1.575,1.575,0,0,1,1147.595,467.656Zm-15.755-17.417,15.755,15.755,1.637-1.637L1133.477,448.6Z"
        transform="translate(-1109.169 -440.512)"
      />
      <path
        className="a"
        d="M1270.886,460.127a.819.819,0,0,1-.579-.24l-13.973-13.973a.82.82,0,0,1,.421-1.384l6.447-1.271a.823.823,0,0,1,.738.225l8.8,8.8a.82.82,0,0,1,.225.738l-1.271,6.447a.82.82,0,0,1-.8.661Zm-12.306-14.285,11.8,11.8.889-4.51-8.178-8.178Z"
        transform="translate(-1231.135 -436.934)"
      />
      <path
        className="a"
        d="M1502.493,360.664a.816.816,0,0,1-.579-.24l-6.7-6.7a.819.819,0,0,1,0-1.159l3.443-3.443a2.813,2.813,0,0,1,3.978,0l3.881,3.882a2.817,2.817,0,0,1,0,3.978l-3.443,3.443A.817.817,0,0,1,1502.493,360.664Zm-5.542-7.52,5.542,5.541,2.863-2.863a1.175,1.175,0,0,0,0-1.66l-3.882-3.882a1.2,1.2,0,0,0-1.66,0Z"
        transform="translate(-1462.519 -344.965)"
      />
      <path
        className="a"
        d="M1223.959,1090.346a.82.82,0,0,1-.36-1.557,49.359,49.359,0,0,0,13.006-9.458,52.61,52.61,0,0,0,6.154-7.377.82.82,0,1,1,1.351.928,54.241,54.241,0,0,1-6.346,7.607,50.994,50.994,0,0,1-13.448,9.772A.817.817,0,0,1,1223.959,1090.346Z"
        transform="translate(-1199.214 -1045.575)"
      />
      <path
        className="a"
        d="M569.156,362.365a.82.82,0,0,1-.736-1.179,50.988,50.988,0,0,1,9.772-13.447,54.221,54.221,0,0,1,7.608-6.346.82.82,0,0,1,.928,1.351,52.571,52.571,0,0,0-7.377,6.154,49.359,49.359,0,0,0-9.457,13.006A.819.819,0,0,1,569.156,362.365Z"
        transform="translate(-564.956 -338.137)"
      />
      <path
        className="a"
        d="M1331.726,1189.749a.82.82,0,0,1-.416-1.526,63.607,63.607,0,0,0,12.662-9.888,66.833,66.833,0,0,0,6.3-7.272.82.82,0,1,1,1.314.98,68.5,68.5,0,0,1-6.458,7.451,65.247,65.247,0,0,1-12.99,10.142A.819.819,0,0,1,1331.726,1189.749Z"
        transform="translate(-1303.6 -1141.597)"
      />
      <path
        className="a"
        d="M461.39,262.921a.82.82,0,0,1-.706-1.235,65.244,65.244,0,0,1,10.142-12.99,68.492,68.492,0,0,1,7.451-6.458.82.82,0,1,1,.98,1.314,66.86,66.86,0,0,0-7.273,6.3,63.593,63.593,0,0,0-9.888,12.662A.819.819,0,0,1,461.39,262.921Z"
        transform="translate(-460.571 -242.075)"
      />
  </SvgIcon>
);

export default MergeIcon;
