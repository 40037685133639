import React from 'react'
import { View } from 'react-native'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ItText from '../../../components/ItText';
import { Block } from '../../../components/views';
import { ItButton } from '../../../components/buttons';
import { Divider } from 'react-native-elements';
import { primary } from '../../../theme/colors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCalculationResult } from '../diaCalculationState';



const ObservationsOverlay = ({ isVisible, onBackdropPress }: any) => {
  const { t } = useTranslation();
  const data = useSelector(selectCalculationResult);

  console.log(data)
  return (
    <Dialog
      open={isVisible}
      onClose={onBackdropPress}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ width: '100%', padding: 0 }}>
        <img src={require('../assets/overlayImg.png')} alt="" width="100%"></img>
        <View style={{ padding: 20, alignItems: 'center' }}>
          <Block>
            <ItText primary style={{ textAlign: 'center' }}>{t('OBSERVACIONES')}</ItText>
          </Block>
          <Divider style={{ width: 60, backgroundColor: primary, marginTop: 10, marginBottom: 15 }}></Divider>
          <View>
            <div style={{ color: '#9997A2'}} dangerouslySetInnerHTML={{ __html: data.volume_source.observations}}></div>
            <ItButton title={t('ACEPTAR')} onPress={() => onBackdropPress()} containerStyle={{ marginTop: 20 }}></ItButton>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  )
}

export default ObservationsOverlay;
