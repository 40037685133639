import AsyncStorage from '@callstack/async-storage';
import { Reducer, Action } from 'redux';
import { ItUser, ItThunkAction } from '../../store';

export const AUTH_ME = 'AUTH/ME';
export const LOGOUT = 'USER/LOGOUT';

export const setInfo = (info: ItUser): Action & { info: ItUser } => {
  return {
    type: 'USER/SET_INFO',
    info,
  };
};

export const logout = (): ItThunkAction => {
  return async (dispatch): Promise<void> => {
    await AsyncStorage.removeItem('token');
    dispatch({ type: LOGOUT });
  };
};

export function authGetMe(): ItThunkAction {
  return async (dispatch): Promise<void> => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await fetch(`https://skandia-api.inmov.info/api/v1/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401 || response.status === 400 || !response.ok) {
        dispatch(logout());
        window.location.href = '/';
        return Promise.resolve();
      }
      const responseJson = await response.json();
      dispatch(setInfo(responseJson));
    } catch (ex) {
      console.error(ex);
      return Promise.resolve();
    }
  };
}

const initialState = {};

export const userReducer: Reducer = (state = initialState, action) => {
  if (action.type == 'USER/SET_INFO') {
    return {
      ...state,
      info: action.info,
    };
  }
  if (action.type == LOGOUT) {
    return initialState;
  }
  return state;
};
