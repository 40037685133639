import React from 'react'
import { ScrollView, View } from 'react-native'
import { Card } from 'react-native-elements'
import { itS, itVs } from '../../../libs/resize'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AsyncStorage from '@callstack/async-storage';
import env from 'react-native-config';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/HomeOutlined';
import IconButton from '@material-ui/core/IconButton';
import DcCalculationTabs from '../components/DcCalculationTabs'
import { ScreenMaxSize, Block } from '../../../components/views'
import ItText from '../../../components/ItText'
import DcSectionTitle from '../components/DcSectionTitle'
import ECValue from '../components/ECValue';
import DcPrice from '../components/DcPrice'
import { YellowInput, EcValuePalid, LightgrayBox } from '../components/DcUi';
import Fab from '@material-ui/core/Fab';
import RentabilityCollapsible from '../components/RentabilityCollapsible'
import { primary } from '../../../theme/colors'
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro'
import ObservationsOverlay from '../components/ObservationsOverlay'
import { selectCalculationResult, SET_CALCULATION_RESULT } from '../diaCalculationState'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, FastField } from 'formik'
import DCTaxesCard from '../components/DCTaxesCard'
import { ItButton } from '../../../components/buttons'
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'
import { getLang } from '../../../i18n/I18NConfig'
import { useTranslation } from 'react-i18next'
import PageImgBackground from '../components/PageImgBackground'
import DcPercent from '../components/DcPercent'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const VolumeSource = (props: any) => {
  const classes = useStyles();
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const data = useSelector(selectCalculationResult);
  const [isCalculating, setIsCalculating] = React.useState(false);
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { profability_detail } = data.profability.volume_source;

  
console.log('es', profability_detail);

  return (
    <>
      <AppBar position="fixed" style={{ background: '#2C2C2C' }}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back" onClick={() => {
            props.history.push('/')
          }}>
            <BackIcon></BackIcon>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('CALCULO')}
        </Typography>
        </Toolbar>
      </AppBar>
      <ScrollView contentContainerStyle={{ paddingBottom: 70, flex: 1 }} style={{ flex: 1 }}>
        <PageImgBackground>
          <RentabilityCollapsible></RentabilityCollapsible>
          <ScreenMaxSize style={{ marginHorizontal: 20 }}>
            <Card containerStyle={{ paddingHorizontal: itS(25), paddingVertical: itVs(22) }}>
              <ItText primary style={{ textAlign: 'center', lineHeight: itS(18) }} strong size={15}>{t('FUENTE DE VOLUMEN')}</ItText>
              <ItText style={{ textAlign: 'center', color: '#1B232E', marginVertical: 17 }} size={14}>{t('Puede editar los campos que se encuentran en color amarillo')}</ItText>
              <DcSectionTitle title={`${t('RENTABILIDAD')} ${data.volume_source.name.toUpperCase()}`}></DcSectionTitle>
              <Formik
                enableReinitialize
                initialValues={{
                  cost: data.cost_volume_source,
                  cant: data.ml_shot_volume_source,
                  priceVS: profability_detail.fv_sales_price,
                  cantmlbotella: profability_detail.capacity_volume_source,
                  other_expenses_volume_source: parseFloat(profability_detail.other_expenses).toFixed(2),
                }}
                onSubmit={async (values) => { 
                  setIsCalculating(true)
                  try {
                    const res = await fetch(`${env.API_HOST}/api/v1/visits/${data.id}`, {
                      method: 'put',
                      headers: {
                        Authorization: `Bearer ${await AsyncStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-Language': await getLang()
                      },
                      body: JSON.stringify({
                        "cost_volume_source": values.cost,
                        "price_volume_source": values.priceVS,
                        "cost_product": data.cost_product,
                        type_result: data.type_result,
                        "price_product": data.price_product,
                        "ml_shot_volume_source": values.cant,
                        "ml_shot_product": data.ml_shot_product,
                        "capacity_product": data.capacity_product,
                        "cocktail_sales_price_product": data.cocktail_sales_price_product,
                        "volume_source_sales_price_shot": data.volume_source_sales_price_shot,
                        "ingredients": data.profability.product_diageo.ingredients,
                        "capacity_volume_source": values.cantmlbotella,
                        "other_expenses_volume_source": values.other_expenses_volume_source,
                        other_expenses_product: parseFloat(data.profability.product_diageo.profability_coctail_detail.other_expenses).toFixed(2)
                      })
                    })
                    if (!res.ok) {
                      const error = t('Error conectando con el servidor');
                      if (res.status == 400) {
                        const errorJSON = await res.json();
                        Swal.fire({
                          title: 'Error!',
                          text: errorJSON.data,
                          icon: 'error',
                          confirmButtonText: 'Ok'
                        })
                        return;
                      }
                      Swal.fire({
                        title: 'Error!',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                      })
                    }
                    const resJson = await res.json();
                    dispatch({
                      type: SET_CALCULATION_RESULT,
                      result: resJson.data
                    })
                  } catch (ex) {
                    Swal.fire({
                      title: 'Error!',
                      text: t('Error conectando con el servidor'),
                      icon: 'error',
                      confirmButtonText: 'Ok'
                    })
                  }
                  finally {
                    setIsCalculating(false)
                  }
                }}
              >
                {({ values, handleSubmit }) => (
                  <>
                    <Loader
                      loading={isCalculating}
                      message={t('Calculando')}
                    ></Loader>
                    <Block>
                      <LightgrayBox text={t('INGREDIENTE')} />
                      <ECValue text={t('TRAGO')} />
                    </Block>
                    <Block>
                      <LightgrayBox text={t('Costo')} />
                      <FastField name="cost">
                        {({ field, form, meta }: any) => (
                          <YellowInput
                            value={field.value}
                            onChangeText={nv => form.setFieldValue(field.name, nv)}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                            // @ts-ignore
                            errorMessage={meta.touched ? meta.error : undefined}
                          />
                        )}
                      </FastField>
                    </Block>
                    <Block>
                      <LightgrayBox text={t('Un Medida')} />
                      <ECValue text="ML" />
                    </Block>
                    <Block>
                      <LightgrayBox text={t('Cant. ML Shot')} />
                      <FastField name="cant">
                        {({ field, form, meta }: any) => (
                          <YellowInput
                            value={field.value}
                            onChangeText={nv => form.setFieldValue(field.name, nv)}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                            // @ts-ignore
                            errorMessage={meta.touched ? meta.error : undefined}
                          />
                        )}
                      </FastField>
                    </Block>
                    <Block>
                      <LightgrayBox text={t('Cant ML Botella')} />
                      <FastField name="cantmlbotella">
                        {({ field, form, meta }: any) => (
                          <YellowInput
                            value={field.value}
                            onChangeText={nv => form.setFieldValue(field.name, nv)}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                            // @ts-ignore
                            errorMessage={meta.touched ? meta.error : undefined}
                          />
                        )}
                      </FastField>
                    </Block>
                    <Block>
                      <LightgrayBox text={t('Valor Und')} />
                      <DcPrice
                        containerStyle={{ flex: 1 }}
                        value={(values.cost / values.cantmlbotella) * values.cant}
                      ></DcPrice>
                    </Block>
                    <Block>
                      <LightgrayBox text="Total" />
                      <DcPrice
                        containerStyle={{ flex: 1 }}
                        value={values.cost}
                      ></DcPrice>
                    </Block>
                  <View style={{ backgroundColor: '#F7F4F2', marginHorizontal: -25, paddingHorizontal: 25, paddingVertical: 20, marginTop: 20 }}>
                    <Block>
                      <EcValuePalid text={t('Total Costos Insumos')} style={{
                        marginRight: 11,
                      }}></EcValuePalid>
                      <DcPrice
                        containerStyle={{ flex: 1 }}
                        value={profability_detail.total_ingredients}
                      ></DcPrice>
                    </Block>
                    <Block>
                      <EcValuePalid text={t('Otros Gastos')} style={{
                        marginRight: 11,
                      }}></EcValuePalid>
                      <FastField name="other_expenses_volume_source">
                      {({ field, form, meta }: any) => (
                        <YellowInput
                          inputStyle={{
                            color: primary,
                            fontWeight: 'bold'
                          }}
                          value={field.value}
                          onChangeText={nv => form.setFieldValue(field.name, nv)}
                          onBlur={() => form.setFieldTouched(field.name, true)}
                          // @ts-ignore
                          errorMessage={meta.touched ? meta.error : undefined}
                        />
                      )}
                    </FastField>
                    </Block>
                    <Block>
                      <EcValuePalid text={t('Total Costos Producto')} style={{
                        marginRight: 11,
                      }}></EcValuePalid>
                      <DcPrice
                        containerStyle={{ flex: 1 }}
                        value={profability_detail.total_cost_fv}
                      ></DcPrice>
                    </Block>
                    <Block>
                      <EcValuePalid text={t('Precio S Venta')} style={{
                        marginRight: 11,
                      }} textStyle={{ color: primary, fontWeight: 'bold' }}></EcValuePalid>
                      <FastField name="priceVS">
                        {({ field, form, meta }: any) => (
                          <YellowInput
                            value={field.value}
                            onChangeText={nv => form.setFieldValue(field.name, nv)}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                            // @ts-ignore
                            errorMessage={meta.touched ? meta.error : undefined}
                          />
                        )}
                        </FastField>
                    </Block>
                      {profability_detail.taxes.map((tax: any) => (
                        <DCTaxesCard tax={tax} key={tax.id}></DCTaxesCard>
                      ))}
                      <Block>
                        <EcValuePalid text={t('Rentabilidad')} style={{
                          marginRight: 11,
                        }}></EcValuePalid>
                        <DcPrice
                          containerStyle={{ flex: 1 }}
                          value={profability_detail.rentability_fv}
                        ></DcPrice>
                      </Block>
                      <Block>
                        <EcValuePalid text={t('% Costo')} style={{
                          marginRight: 11,
                        }}></EcValuePalid>
                        <DcPercent
                          containerStyle={{ flex: 1 }}
                          value={profability_detail.cost_percent}
                        ></DcPercent>
                      </Block>
                      <Block>
                        <EcValuePalid text={t('% Rentabilidad')} style={{
                          marginRight: 11,
                        }}></EcValuePalid>
                        <DcPercent
                          containerStyle={{ flex: 1 }}
                          value={parseFloat(profability_detail?.rent_percent)}
                        ></DcPercent>
                      </Block>
                      <ItButton title={t('CALCULAR')} containerStyle={{ marginTop: 10 }} onPress={() => handleSubmit()}></ItButton>
                    </View>
                  </>
                )}
              </Formik>
            </Card>
          </ScreenMaxSize>
        </PageImgBackground>
      </ScrollView>
      <Fab color="secondary" onClick={() => {
        setIsOverlayOpen(true);
      }}>
        <FontAwesome5Icon name="list" light size={35} color="inherit"></FontAwesome5Icon>
      </Fab>
      <DcCalculationTabs></DcCalculationTabs>
      <ObservationsOverlay isVisible={isOverlayOpen} onBackdropPress={() => setIsOverlayOpen(false)}>
      </ObservationsOverlay>
    </>
  )
}




export default withRouter(VolumeSource)
