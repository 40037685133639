import React from 'react'
import { View } from 'react-native'
import env from 'react-native-config'
import IngredientEditableItem from './IngredientEditableItem'
import { ItButton } from '../../../components/buttons'
import {  Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { selectCalculationResult, SET_CALCULATION_RESULT } from '../diaCalculationState'
import * as Yup from 'yup';
import AsyncStorage from '@callstack/async-storage';
/* import ItText from '../../../components/ItText' */
import { itS, itVs } from '../../../libs/resize'
import Loader from '../../../components/Loader';
import { getLang } from '../../../i18n/I18NConfig'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import IngredientEditableItemRecipe from './IngredientEditableItemRecipe'

const DCCalculationDetailForm = ({
  noredirect,
  children,
  isRecipe
}: {
  noredirect?: boolean,
  children?: React.ReactElement | React.ReactElement[],
  isRecipe?: boolean,
}) => {
  const data = useSelector(selectCalculationResult);
  const [isCalculating, setIsCalculating] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory();
  const validationSchema = React.useMemo(() => (
    Yup.object().shape({
      ingredients: Yup.array().of(Yup.object().shape({
        quantity: Yup.string().required(t('Campo requerido')).min(0),
        price_unit: Yup.string().when('product_diageo', {
          is: 'Y',
          then: Yup.string().nullable(),
          otherwise: Yup.string()
            .required(t('Campo requerido')),
        }),
      })),
    })
  ), [t]);
  
  const ingredients = React.useMemo(() => data.product.ingredients, [data]);

  
  const initialValues = React.useMemo(() => ({
    ingredients: data.profability.product_diageo.ingredients,
    cocktail_sales_price_product: data.profability.product_diageo.profability_coctail_detail.coctail_sales_price,
    other_expenses_product: parseFloat(data.profability.product_diageo.profability_coctail_detail.other_expenses).toFixed(2)
  }), [data])
  const submit = React.useCallback(async (values) => {
    setIsCalculating(true)
    try {
      console.log('VALUEAS',values);
      
      const res = await fetch(`${env.API_HOST}/api/v1/visits/${data.id}`, {
        method: 'put',
        headers: {
          Authorization: `Bearer ${await AsyncStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Language': await getLang()
        },
        body: JSON.stringify({
          "cost_volume_source": data.cost_volume_source,
          "price_volume_source": data.price_volume_source,
          "cost_product": data.cost_product,
          "price_product": data.price_product,
          type_result: data.type_result,
          "capacity_volume_source": data.capacity_volume_source,
          "ml_shot_volume_source": data.ml_shot_volume_source,
          "ml_shot_product": data.ml_shot_product,
          "capacity_product": data.capacity_product,
          "cocktail_sales_price_product": values.cocktail_sales_price_product || data.cocktail_sales_price_product,
          "volume_source_sales_price_shot": data.volume_source_sales_price_shot,
          "ingredients": values.ingredients,
          "other_expenses_volume_source": data.profability.volume_source.profability_detail.other_expenses,
          "other_expenses_product": values.other_expenses_product,
        })
      })
      if (!res.ok) {
        const error = t('Error conectando con el servidor');
        if (res.status == 400) {
          const errorJSON = await res.json();
          Swal.fire({
            title: 'Error!',
            text: errorJSON.data,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return;
        }
        Swal.fire({
          title: 'Error!',
          text: error,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
      const resJson = await res.json();
      dispatch({
        type: SET_CALCULATION_RESULT,
        result: resJson.data
      })
      if (!noredirect) { 
        setTimeout(() => {
          history.push('/calculation-result')
        }, 100)
      }
    } catch (ex) { 
      Swal.fire({
        title: 'Error!',
        text: t('Error conectando con el servidor'),
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
    finally {
      setIsCalculating(false)
    }
  }, [data.capacity_product, data.capacity_volume_source, data.cocktail_sales_price_product, data.cost_product, data.cost_volume_source, data.id, data.ml_shot_product, data.ml_shot_volume_source, data.price_product, data.price_volume_source, data.profability.volume_source.profability_detail.other_expenses, data.type_result, data.volume_source_sales_price_shot, dispatch, history, noredirect, t])
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ handleSubmit }) => (
        <>
          <Loader
            loading={isCalculating}
            message={t('Calculando')}
          ></Loader>
          <View style={{ paddingHorizontal: itS(25), paddingVertical: itVs(22) }}>
            {ingredients.map((p: any, index: number) => (
              isRecipe ? <IngredientEditableItemRecipe
                isDiageoIngredient={p.product_diageo == 'Y'}
                ingredient={p}
                index={index.toString()}
                key={`item_${p.id}`}
              /> : <IngredientEditableItem
                isDiageoIngredient={p.product_diageo == 'Y'}
                ingredient={p}
                index={index.toString()}
                key={`item_${p.id}`}
              />
            ))}
            {children}
            <ItButton onPress={() => handleSubmit()} title={t('CALCULAR')} style={{ marginBottom: 15, marginTop: 10 }}></ItButton>
          </View>
        </>
      )}
    </Formik>
  )
}

export default DCCalculationDetailForm
